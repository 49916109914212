<!--用户信息修改-->
<template>
  <div class="mbox1">
    <h2>用户信息修改</h2>
    <el-form ref="formRef" :model="form" :rules="rules" label-width="120px">
      <h4>基本信息</h4>
      <el-input v-model="form.id" type="hidden"></el-input>
      <el-form-item label="用户名" prop="username">
          <el-input v-model="form.username" readonly class="width"></el-input>
      </el-form-item>      
      <el-form-item label="联系人" prop="linkman">
        <el-input v-model="form.linkman" class="width"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio-group v-model="form.sex">
          <el-radio-button label="0">男</el-radio-button>
          <el-radio-button label="1">女</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="联系电话" prop="phoneNumber">
          <el-input v-model="form.phoneNumber" maxlength="50" class="width1"></el-input>
      </el-form-item>
      <el-form-item label="电子邮件" prop="email">
        <el-input v-model="form.email" maxlength="100" class="width1"></el-input>
      </el-form-item>
  <h4>企业信息</h4>
      <el-form-item label="企业名称" prop="nickName">
          <el-input v-model="form.nickName" class="width1"></el-input>
      </el-form-item>
      <el-form-item label="法定代表人" prop="legalPerson">
        <el-input v-model="form.legalPerson" maxlength="50" class="width"></el-input>
      </el-form-item>
      <el-form-item label="法人电话" prop="legalMobile">
        <el-input v-model="form.legalMobile" maxlength="50" class="width"></el-input>
      </el-form-item>
      <el-form-item label="企业地址" prop="address">
          <el-input v-model="form.address" maxlength="100" show-word-limit class="width1"></el-input>
      </el-form-item>
      <el-form-item label="信用代码" prop="creditCode">
          <el-input v-model="form.creditCode" maxlength="100" show-word-limit class="width1"></el-input>
      </el-form-item>
      <el-form-item label="产业" prop="industryId">
        <el-select v-model="form.industryId" multiple filterable clearable placeholder="请选择行业" class="width1">
          <el-option v-for="item in industryData" :key="item.dictValue"
            :label="item.dictLabel" :value="item.dictValue">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" type="textarea" maxlength="200" show-word-limit></el-input>
      </el-form-item>      
    </el-form>    
    <div class="mbox-footer">
      <el-button @click="resetForm">重 填</el-button>
      <el-button type="primary" @click="saveInfo">确 定</el-button>
    </div>    
  </div>
</template>

<script>
export default {
  name: 'useredit',
  data () {
    return {
      // 当前登录用户名
      uname: '',
      userData: null,
      userStatus: '2',
      //审核意见
      userAudit: '',
      form: { 
        // deptId: '1',
        // userType: '2', 
        id: '',
        username: '',        
        linkman: '',
        sex: '0',
        phoneNumber: '',
        email: '',
        //企业名称
        nickName: '',
        legalPerson: '',
        legalMobile: '',        
        address: '',
        industryId: [],
        creditCode: '',
        remark: ''        
      },
      rules: {        
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],        
        linkman: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        phoneNumber: [
          { required: true, message: '请输入联系人电话', trigger: 'blur' }          
        ],
        email: [
          { required: true, message: '请输入邮件地址', trigger: 'blur' },
          {            
            validator: (rule, value, callback) => {              
              if ( !/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(value)) {
                // this.$message.error("邮件地址格式不正确")
                callback(new Error('邮件地址格式不正确'))
                return
              }
              callback()
            },
            trigger: "blur"
          }
        ],
        nickName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }          
        ],
        legalPerson: [
          { required: true, message: '请输入法定代表人', trigger: 'blur' }          
        ],
        legalMobile: [
          { required: true, message: '请输入法人电话', trigger: 'blur' }          
        ],
        address: [
          { required: true, message: '请输入企业地址', trigger: 'blur' }          
        ],
        creditCode: [
          { required: true, message: '请输入企业信用代码', trigger: 'blur' }          
        ],
        industryId: [
          { required: true, message: '请选择行业', trigger: 'blur' }          
        ]
      },
      industryData: []
    }
  },
  mounted () {
    // document.title = '云南省大学科技园'
    // 获取本地信息 用户登录成功后取用户名
    const ui = this.$api.ui    
    if (ui !== null) {
      this.uname = ui.uname
    } else {
      this.$router.push({ path: '/login' })            
    }    
    this.editInfo()
  },
  methods: {
    // 获取产业数据
    async getIndustryData () {
      const { data: ret } = await this.$http.get(this.$api.dictItemlist + '?dictType=industry_type')      
      if (ret.code === 1) {
        if (ret.data === null) {          
          this.$message({
            message: ret.message,
            type: 'warning'
          })
          return
        }
        this.industryData = ret.data
        console.log('IndustryData=', ret.data)
      }
    },
    async editInfo () {
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   // spinner: 'el-icon-loading',
      //   // background: 'rgba(0, 0, 0, 0.7)'
      // })
      this.getIndustryData()
      const params = '?username=' + this.uname
      // console.log('this.uname=', this.uname)
      const { data: ret } = await this.$http.get1(this.$api.userinfoByuname + params)     
      if (ret.code === 1 && ret.data !== null) {
        console.log('edituser=', ret.data)
        const obj = ret.data
        Object.keys(this.form).forEach(key => {
          this.form[key] = obj[key]
        })
        this.form['industryId'] = JSON.parse(ret.data.industryId)
      } else {
        this.$message.error(ret.msg)
      }
      // loading.close()
    },
    async saveInfo () {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {          
          // console.log('form=', this.form)
          const { data: ret } = await this.$http.post1(this.$api.useredit, this.form) 
          // console.log('retOK=', ret)
          if (ret.code === 1) {
            this.$message.success(ret.msg)            
          } else {
            this.$message.error(ret.msg)
          }
        } else {
          this.$message.error('验证没通过。')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.formRef.resetFields()
    }
  }
}
</script>

<style scoped>
</style>